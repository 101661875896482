<template>
  <form
    class="form_search"
    @submit.prevent="goResultPage"
  >
    <div class="wrap_input">
      <input
        id="inputSearch"
        v-model.trim="searchKeyword"
        type="text"
        placeholder="검색어를 입력하세요"
        autocomplete="off"
        maxlength="50"
      /><label for="inputSearch"><span class="screen_out">검색어</span></label>
      <button
        v-if="searchKeyword.length > 0"
        type="button"
        class="btn_remove"
        @click="removeKeyword"
      >
        <span class="screen_out">검색어 삭제하기</span>
      </button>
    </div>
    <div
      v-if="listTags.length > 0 || listTags === null"
      class="list_suggest"
    >
      <strong>추천 검색어</strong>
      <div class="wrap_tags">
        <LinkBasic
          v-for="(item, idx) in listTags"
          :key="`${idx}_tag`"
          :text="`#${item.term}`"
          :link="serachUrl(item.term)"
          :isBlank="false"
          class="item_suggest"
          data-tiara-action-name="추천검색어 클릭"
          :data-tiara-search_term="item.term"
          data-tiara-search_type="추천검색어_클릭"
        />
      </div>
    </div>
  </form>
</template>
<script setup>
import { fetchSuggests } from '/api/admin';
import { useSearchLayer, useTotalSearchKeyword } from '/stores/store';
const searchLayer = useSearchLayer();
const totalSearchKeyword = useTotalSearchKeyword();
const props = defineProps({
  type: {
    type: String,
    default: '',
  },
});
const searchKeyword = ref('');

const serachUrl = (value) => {
  return `/search?searchKeyword=${value}`;
};

const router = useRouter();
const route = useRoute();

const goResultPage = () => {
  //특수문자 검색방지
  if (specialCharReg.test(searchKeyword.value)) {
    alert('특수문자는 입력이 불가합니다');
    return;
  }
  //인풋값이 2자 이상일때! 인풋에서 입력완료시 검색결과 페이지로 이동
  if (searchKeyword.value.length < 2) {
    window.alert('검색어는 2자 이상 입력해주세요');
  } else {
    TiaraTrackSearch(searchKeyword.value, '통합검색_검색어검색'); //티아라 검색어 수집
    router.push(serachUrl(searchKeyword.value));
    searchLayer.toggleSearchLayer('close');
  }
};

const removeKeyword = () => {
  searchKeyword.value = '';
};

const setInputKeyword = () => {
  //공통검색값이 있으면 인풋에 넣어주기
  searchKeyword.value = totalSearchKeyword.searchWord || '';
};

const { data: listTags } = await useAsyncData('suggest', () => fetchSuggests(), {
  default: () => {},
});

watch(
  () => route.fullPath,
  () => {
    setInputKeyword();
  },
  { immediate: true },
);

onUnmounted(() => {
  if (props.type === 'page') {
    totalSearchKeyword.setTotalSearchKeyword('');
  }
});
</script>
<style scoped lang="scss">
.form_search {
  width: 100%;
  .wrap_input {
    position: relative;
    padding: 16px 16px 16px 100px;
    border-bottom: 1px solid #000;
    @include searchLensIcon();
    background-size: 60px 60px;
    background-position: left 16px top 25px;
    input {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      height: 78px;
      line-height: 78px;
      font-size: 52px;
      font-weight: 700;
      color: #1a1a1a;
      border: 0;
      outline: none;
      &::placeholder {
        color: #e5e5e5;
      }
    }
  }
  .list_suggest {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    text-align: left;
    strong {
      margin-right: 40px;
      font-weight: 400;
    }
    strong,
    a {
      font-size: 16px;
      line-height: 1.75;
      white-space: nowrap;
      vertical-align: top;
    }
    .wrap_tags {
      margin-left: -24px;
    }
    .item_suggest {
      margin-left: 24px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .btn_remove {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  .form_search {
    .wrap_input {
      padding: 8px 35px 8px 58px;
      background-size: 30px 30px;
      background-position: left 12px top 11px;
      input {
        height: 36px;
        line-height: 36px;
        font-size: 24px;
      }
    }
    .list_suggest {
      padding: 0 8px;
      strong {
        margin-right: 16px;
      }
      strong,
      a {
        font-size: 12px;
        line-height: 1.5;
      }
      .wrap_tags {
        margin-left: -8px;
        text-align: left;
      }
      .item_suggest {
        margin-left: 8px;
      }
    }
    .btn_remove {
      display: block;
      position: absolute;
      top: 11px;
      right: 8px;
      @include setSize(30px, 30px);
      @include backgroundImage(
        "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' fill='none'%3E%3Ccircle cx='15' cy='15' r='11' fill='%23CCC'/%3E%3Cpath d='m12 12 6 6m-6 0 6-6' stroke='%23fff' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E",
        cover,
        top center
      );
    }
  }
}
</style>
